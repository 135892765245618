(function($) {
    $(document).ready(function(){
        advancedSearch();
        $(".prdctfltr_wc").show();
        if(window.location.href.indexOf("pa_")!== -1) {
            var target = $(".productfilter");
            if (target.length) {
                var scrollTo = target.offset().top -30;
                $('html,body').animate({
                    scrollTop: scrollTo
                }, 1000);
                return false;
            }
            $(".prdctfltr_filter").each(function(index) {
                if(index > 4)
                    $(this).slideToggle();
            });
        }
    });
    $( document ).ajaxComplete(function( event, request, settings ) {
        //advancedSearch();
    });

    function advancedSearch() {
        $(".prdctfltr_clear").each(function(index) {
            if(index == 0) {
                $(this).addClass("advancedsearch");
                var elm = $(this);
                elm.insertAfter(elm.next());
            }
            else
                $(this).remove();
        });
        $(".prdctfltr_filter").each(function(index) {
            if (index == 5) {
                console.log("test");
                $(this).before('<div class="prdctfltr_clear advancedsearch"></div>');
            }
            if(index > 4)
                $(this).hide();
        });
        /*if($(".prdctfltr_collector > span").length > 0) {
            $(".prdctfltr_filter").each(function(index) {
                if(index > 4)
                    $(this).hide();
            });

            $(".prdctfltr_clear.advancedsearch").toggleClass("toggled");
        }*/
        $(".advancedsearch").click(function(){
            $(".prdctfltr_filter").each(function(index) {
                if(index > 4)
                    $(this).slideToggle();
            });

            $(".prdctfltr_clear.advancedsearch").toggleClass("toggled");
        });
    }
})( jQuery );