jQuery(document).ready(function ($) {
    jQuery("#menu-category-menu > li.menu-item-has-children > a").each(function() {
        jQuery(this).append("<span class='menu-span'></span>");
    });
    jQuery("#menu-category-menu > li.menu-item-has-children > a").click(function(e){
        e.preventDefault();
        jQuery(this).find("span").toggleClass("menu-open");
        var submenu = jQuery(this).parent().find(".sub-menu");
        jQuery(submenu).slideToggle(500);
    });
});

function updateSpinner(obj){
    var contentObj = jQuery(obj).parent().find(".quantity .input-text");
    var value = parseInt(jQuery(contentObj).val());
    if(obj.id == "quantity-down") {
        if(value > 1){
            value--;
        }
    } else {
        value++;
    }
    jQuery(contentObj).val(value);

};